const fallback = "en-GB";

const localesSupportedByEcomWidget = [
  "bg-BG",
  "cs-CZ",
  "da-DK",
  "de-DE",
  "el-CY",
  "el-GR",
  fallback,
  "en-IE",
  "en-MT",
  "en-US",
  "es-CL",
  "es-ES",
  "et-EE",
  "fi-FI",
  "fr-BE",
  "fr-CH",
  "fr-FR",
  "fr-LU",
  "hu-HU",
  "it-CH",
  "it-IT",
  "lt-LT",
  "lv-LV",
  "nb-NO",
  "nl-BE",
  "nl-NL",
  "pt-BR",
  "pt-PT",
  "pl-PL",
  "sk-SK",
  "sl-SI",
  "sv-SE",
];

var localesSupportedByEcomWidgetCount = localesSupportedByEcomWidget.length;

const matchingLocale = (locale) => {
  if (localesSupportedByEcomWidget.indexOf(locale) >= 0) {
    return locale;
  }

  // try to find match based on language
  const components = locale.split("-");
  if (components.length >= 1) {
    const language = components[0];
    for (let i = 0; i < localesSupportedByEcomWidgetCount; i++) {
      const supportedLocale = localesSupportedByEcomWidget[i];
      if (supportedLocale.lastIndexOf(language) === 0) {
        return supportedLocale;
      }
    }
  }
  return fallback;
};

export default matchingLocale;
