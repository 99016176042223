/** @jsxImportSource @emotion/core */
import { Fragment, useContext } from "react";
import { Card, Text, Hr } from "@sumup/circuit-ui";
import { css } from "@emotion/core";
import { withTheme } from "emotion-theming";

import CardHeading from "../../layout/CardHeading";
import TranslationContext from "../../../translation-context";
import NumberFormatter from "../../../utils/numberFormatter";
import { minorToMajorUnits } from "../../../utils/currency/units";

const sectionHeadingStyles = (theme) => css`
  color: ${theme.colors.black};
  margin-top: ${theme.spacings.mega};
`;

const SectionHeading = (props) => (
  <Text as="h4" bold noMargin css={sectionHeadingStyles} {...props} />
);

const hrStyles = css`
  margin-bottom: 0;
`;

const Summary = ({ theme, order, locale, currencyCode }) => {
  const t8n = useContext(TranslationContext);
  let formattedAmount = NumberFormatter(locale)(
    minorToMajorUnits(order.amount, currencyCode),
    currencyCode,
  );

  let paidAmount = order.paid_amount
    ? NumberFormatter(locale)(
        minorToMajorUnits(order.paid_amount, currencyCode),
        currencyCode,
      )
    : formattedAmount;
  let discount = order.paid_amount
    ? NumberFormatter(locale)(
        minorToMajorUnits(order.amount - order.paid_amount, currencyCode),
        currencyCode,
      )
    : null;
  function dataRow(label, value) {
    return (
      <Fragment>
        <SectionHeading>{label}</SectionHeading>
        <Text noMargin>{value}</Text>
      </Fragment>
    );
  }
  const recipientDiffers =
    order.recipient &&
    (order.recipient.email !== order.buyer.email ||
      order.recipient.name !== order.buyer.name);
  return (
    <Card style={{ padding: theme.spacings.giga }}>
      <CardHeading title={t8n("Order Summary")} style={{ marginBottom: 0 }} />
      <Text noMargin>
        {t8n("Order number")}: {order.code}
      </Text>
      {dataRow(t8n("Gift Card Amount"), formattedAmount)}
      {paidAmount !== formattedAmount && dataRow(t8n("Discount"), discount)}
      {paidAmount !== formattedAmount && dataRow(t8n("Total paid"), paidAmount)}

      <SectionHeading>{t8n("Your details")}</SectionHeading>
      <Text noMargin>{order.buyer.name}</Text>
      <Text noMargin>{order.buyer.email}</Text>

      {recipientDiffers && (
        <Fragment>
          <Hr css={hrStyles} />

          <SectionHeading>{t8n("Recipient’s Information")}</SectionHeading>
          <Text noMargin>{order.recipient.name}</Text>
          <Text noMargin>{order.recipient.email}</Text>

          {order.message && (
            <Fragment>
              <SectionHeading>{t8n("Message")}</SectionHeading>
              <Text noMargin>{order.message}</Text>
            </Fragment>
          )}
        </Fragment>
      )}
    </Card>
  );
};

export default withTheme(Summary);
