import { TYPE } from "./model";
import NumberFormatter from "../numberFormatter";
import { minorToMajorUnits } from "../currency/units";

const title = (t8n, locale, currencyCode, discountRule) => {
  const formatter = NumberFormatter(locale);
  switch (discountRule.type) {
    case TYPE.percentage: {
      const percentageDiscount = `${formatter(discountRule.value / 100)}`;
      if (
        discountRule.maximum_discount_value ||
        discountRule.minimum_voucher_value
      ) {
        return t8n("Up to %s% off", percentageDiscount);
      }
      return t8n("%s% off", percentageDiscount);
    }
    case TYPE.absolute: {
      const discountAbsoluteValue = formatter(
        minorToMajorUnits(discountRule.value, currencyCode),
        currencyCode,
      );
      if (
        discountRule.maximum_discount_value ||
        discountRule.minimum_voucher_value
      ) {
        return t8n("Up to %s off", discountAbsoluteValue);
      }
      return t8n("%s off", discountAbsoluteValue);
    }
    default:
      return null;
  }
};

export default title;
