/** @jsxImportSource @emotion/core */
import { useContext, Fragment } from "react";
import { css } from "@emotion/core";
import { SelectorGroup, Text } from "@sumup/circuit-ui";

import TranslationContext from "../../../translation-context";
import Grid from "../../layout/grid";

const blueStyles = (theme) => css`
  color: ${theme.colors.p500};
`;

export default function PresetPicker({
  amounts,
  selectedAmount,
  numberFormatter,
  onChange,
  discountFunction,
}) {
  const t8n = useContext(TranslationContext);

  const amountsWithDiscount = discountFunction
    ? amounts.filter((amount) => {
        return discountFunction(amount) !== 0;
      })
    : [];

  // Distribute columns evenly
  const columns = amounts.length <= 3 ? amounts.length : 2;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  // ui-library expects values as string, convert amount and selectedAmount to strings
  const options = amounts.map((amount) => {
    const discount = discountFunction ? discountFunction(amount) : 0;
    const formattedPayableAmount = numberFormatter(amount - discount);
    return {
      value: amount.toString(),
      css: (theme) => css`
        padding: ${theme.spacings.mega} ${theme.spacings.byte};
        margin-bottom: 0;
      `,
      children: (
        <Fragment>
          <Text size="mega" bold as="span">
            {numberFormatter(amount)}
          </Text>
          <br />
          {discountFunction && amountsWithDiscount.length > 0 && (
            <Text size="kilo" as="span" css={blueStyles}>
              {t8n("Pay %s", formattedPayableAmount)}
            </Text>
          )}
        </Fragment>
      ),
    };
  });

  return (
    <Grid>
      <SelectorGroup
        onChange={handleChange}
        value={selectedAmount.toString()}
        options={options}
        css={(theme) => css`
          display: grid;
          grid-template-columns: repeat(${columns}, 1fr);
          gap: ${theme.spacings.kilo};
          margin-bottom: ${theme.spacings.mega};
        `}
      />
    </Grid>
  );
}
