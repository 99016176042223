import React, { Fragment, useContext, useEffect } from "react";
import {
  Button,
  Card,
  Heading,
  Label,
  Notification,
  Text,
} from "@sumup/circuit-ui";
import { Field, Form } from "react-final-form";
import { withTheme } from "emotion-theming";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import InputAdapter from "./input";
import TextAreaAdapter from "./textarea";
import { fieldNames } from "./const";
import CheckBoxAdapter from "./checkbox";
import validateEmail from "../../utils/validation/email";
import CardHeading from "../layout/CardHeading";
import SuccessBody from "./successBody";
import DiscountFunction from "../../utils/discounts/discounts";
import TranslationContext from "../../translation-context";
import GoogleAnalytics from "../../utils/analytics/google";
import AmountSelectorAdapter from "./amountSelectorAdapter";
import DiscountsDescription from "../../utils/discounts/description";
import DiscountsTitle from "../../utils/discounts/title";
import { minorToMajorUnits } from "../../utils/currency/units";
import DesignSelectorAdapter from "./designSelectorAdapter";

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const validate = (t8n) => {
  return (values) => {
    if (!values) {
      return;
    }

    const errors = {};
    const amount = values[fieldNames.amount] || 0;

    if (amount === 0) {
      errors[fieldNames.amount] = t8n("Please enter an amount");
    }

    if (!values[fieldNames.buyerName]) {
      errors[fieldNames.buyerName] = t8n("Please enter your name");
    }

    if (!validateEmail(values[fieldNames.buyerEmail])) {
      errors[fieldNames.buyerEmail] = t8n("Please enter your email address");
    }

    if (
      values[fieldNames.recipientDiffers] &&
      !values[fieldNames.recipientName]
    ) {
      errors[fieldNames.recipientName] = t8n(
        "Please enter the recipient's name",
      );
    }

    if (
      values[fieldNames.recipientDiffers] &&
      !validateEmail(values[fieldNames.recipientEmail])
    ) {
      errors[fieldNames.recipientEmail] = t8n(
        "Please enter the recipient's email address",
      );
    }
    return errors;
  };
};

const NameAndEmailGroup = (
  fieldName,
  fieldEmail,
  labelName,
  labelEmail,
  placeholderEmail,
  props,
) => (
  <Fragment>
    <Field
      name={fieldName}
      component={InputAdapter}
      label={labelName}
      disabled={props.isLoading}
      placeholder={labelName}
      required
    />
    <Field
      name={fieldEmail}
      component={InputAdapter}
      label={labelEmail}
      disabled={props.isLoading}
      type="email"
      placeholder={placeholderEmail}
      required
    />
  </Fragment>
);

const GiftCardForm = ({ theme, ...props }) => {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { code, slug } = useParams();
  const t8n = useContext(TranslationContext);
  useEffect(() => {
    GoogleAnalytics.trackPageView("/order/" + code);
  });
  const onSubmit = (values) => {
    props.onSubmitForm(code, slug, SuccessBody(values, props.currency));
  };

  const termsURL = t8n("https://sumup.co.uk/consumer/legal/terms");
  const privacyURL = t8n("https://sumup.co.uk/consumer/legal/privacy");
  const discountFunction = DiscountFunction(props.discounts);
  const presetAmounts = (props.presets || [])
    .map((preset) => minorToMajorUnits(preset.amount, props.currency))
    // make unique
    .filter((v, i, a) => a.indexOf(v) === i);

  // we show the discountDescription and title only if there is single discount
  const showDiscountDesc = props.discounts && props.discounts.length === 1;
  let discountDescriptionText =
    showDiscountDesc &&
    DiscountsDescription(t8n, props.locale, props.currency, props.discounts[0]);
  let discountTitleText =
    showDiscountDesc &&
    DiscountsTitle(t8n, props.locale, props.currency, props.discounts[0]);
  if (discountDescriptionText && discountTitleText) {
    discountTitleText = discountTitleText + " *";
    discountDescriptionText = "* " + discountDescriptionText;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        design:
          props.designs && props.designs.length !== 0 && props.designs[0].id,
      }}
      validate={validate(t8n)}
      render={({
        handleSubmit,
        values,
        submitting,
        pristine,
        errors: validationErrors,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Heading size="giga">{t8n("Buy an online gift card")}</Heading>
            {props.designs && (
              <Field
                name={fieldNames.design}
                component={DesignSelectorAdapter}
                t8n={t8n}
                designs={props.designs}
              />
            )}
            <Card spacing="mega" style={{ marginBottom: theme.spacings.giga }}>
              <CardHeading
                title={t8n("Gift Card Amount")}
                style={discountTitleText ? { margin: 0 } : {}}
              />
              {discountTitleText && (
                <Heading
                  size="kilo"
                  style={{
                    color: theme.colors.b500,
                    marginTop: theme.spacings.byte,
                    marginBottom: theme.spacings.kilo,
                  }}
                >
                  {discountTitleText}
                </Heading>
              )}
              <Field
                type="text"
                name={fieldNames.amount}
                component={AmountSelectorAdapter}
                locale={props.locale}
                currency={props.currency}
                onChange={() => {}}
                discountFunction={discountFunction}
                amounts={presetAmounts}
                allowsCustomAmounts={
                  props.customAmounts && props.customAmounts.permitted
                }
              />
              {discountDescriptionText && (
                <Text size="kilo" noMargin>
                  {discountDescriptionText}
                </Text>
              )}
            </Card>
            <Card spacing="mega" style={{ marginBottom: theme.spacings.giga }}>
              <CardHeading title={t8n("Your information")} />
              {NameAndEmailGroup(
                fieldNames.buyerName,
                fieldNames.buyerEmail,
                t8n("Your Name"),
                t8n("Your Email"),
                t8n("you@example.com"),
                props,
              )}
              <Field
                type="checkbox"
                name={fieldNames.recipientDiffers}
                component={CheckBoxAdapter}
                disabled={props.isLoading}
              >
                {t8n("This gift card is for someone else")}
              </Field>
            </Card>
            <Condition when={fieldNames.recipientDiffers} is={true}>
              <Card
                spacing="mega"
                style={{ marginBottom: theme.spacings.giga }}
              >
                <CardHeading title={t8n("Recipient’s Information")} />
                {NameAndEmailGroup(
                  fieldNames.recipientName,
                  fieldNames.recipientEmail,
                  t8n("Recipient Name"),
                  t8n("Recipient Email"),
                  t8n("recipient@example.com"),
                  props,
                )}
                <Label htmlFor={fieldNames.message}>{t8n("Message")}</Label>
                <Field
                  name={fieldNames.message}
                  component={TextAreaAdapter}
                  disabled={props.isLoading}
                  placeholder={t8n("Enjoy this gift!")}
                />
              </Card>
            </Condition>

            {props.error && (
              <Card
                spacing="mega"
                style={{ marginBottom: theme.spacings.giga }}
              >
                <Notification variant="warning">
                  <Heading size="kilo" as="h4" noMargin>
                    {t8n(
                      "Something went wrong. Please try again in a few minutes.",
                    )}
                  </Heading>
                </Notification>
              </Card>
            )}

            <Card spacing="mega" style={{ marginBottom: theme.spacings.giga }}>
              <div>
                <Field
                  type="checkbox"
                  name={fieldNames.acceptTerms}
                  component={CheckBoxAdapter}
                  disabled={props.isLoading}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t8n(
                        "I agree to the processing and sharing of my personal data as required to use the SumUp Service and as outlined within the <a href='%s'>Terms and Conditions</a> and <a href='%s'>Privacy Policy</a>.",
                        termsURL,
                        privacyURL,
                      ),
                    }}
                  />
                </Field>
              </div>
              <div style={{ paddingTop: theme.spacings.kilo }}>
                <Field
                  type="checkbox"
                  name={fieldNames.acceptMarketingConsent}
                  component={CheckBoxAdapter}
                  disabled={props.isLoading}
                >
                  {t8n(
                    "I want to stay up to date with SumUp's and SumUp merchants' latest news and offers and agree to receive any related communication. (Optional)",
                  )}
                </Field>
              </div>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  !values[fieldNames.acceptTerms] ||
                  (validationErrors && Object.keys(validationErrors).length > 0)
                }
                style={{ width: "100%" }}
              >
                {t8n("Continue")}
              </Button>
            </Card>
          </form>
        );
      }}
    />
  );
};

GiftCardForm.propTypes = {
  currency: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
};

export default withTheme(GiftCardForm);
