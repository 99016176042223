/** @jsxImportSource @emotion/core */
import { useState, useEffect } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Card, IconButton, styleHelpers } from "@sumup/circuit-ui";
import { ChevronLeft, ChevronRight } from "@sumup/icons";

import CardHeading from "../layout/CardHeading";
import Carousel from "../carousel";

const inputStyles = ({ theme }) => css`
  &:hover + span {
    color: ${theme.colors.n700};
  }

  &:focus + span::after {
    ${styleHelpers.focusOutline({ theme })};
  }

  &:checked + span {
    color: ${theme.colors.p500};

    &::after {
      border: 2px solid currentColor;
    }
  }
`;

const Input = styled("input")(styleHelpers.hideVisually, inputStyles);

const dotStyles = ({ theme }) => css`
  display: block;
  height: ${theme.spacings.tera};
  width: ${theme.spacings.tera};
  position: relative;
  cursor: pointer;
  color: ${theme.colors.n500};
  transition: color ${theme.transitions.default};

  &::before,
  &::after {
    box-sizing: border-box;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }

  &::before {
    height: 8px;
    width: 8px;
    background-color: currentColor;
    transition: background-color ${theme.transitions.default};
  }

  &::after {
    height: 16px;
    width: 16px;
    transition: border ${theme.transitions.default};
  }
`;

const Dot = styled("span")(dotStyles);

const Control = ({ label, id, ...props }) => (
  <label htmlFor={id}>
    <span css={styleHelpers.hideVisually}>{label}</span>
    <Input
      {...props}
      id={id}
      type="radio"
      onChange={() => {
        /**
         * Noop to silence React warning:
         * https://github.com/facebook/react/issues/3070#issuecomment-73311114
         * Change is handled by onClick which has better browser support:
         * https://stackoverflow.com/a/5575369/4620154
         */
      }}
    />
    <Dot />
  </label>
);

const cardStyles = (theme) => css`
  margin-bottom: ${theme.spacings.giga};
  padding: 0;
`;

const cardHeadingStyles = (theme) => css`
  padding: ${theme.spacings.mega};
  padding-bottom: 0;
`;

const Controls = styled("fieldset")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(p) => p.theme.spacings.kilo} 0;
  flex-wrap: wrap;
`;

const DesignSelectorAdapter = ({ input, designs = [], t8n }) => {
  const firstIndex = 0;
  const lastIndex = designs.length - 1;
  const [index, setIndex] = useState(firstIndex);

  const activeId = designs[index].id;

  useEffect(() => {
    input.onChange(activeId);
  }, [input, activeId]);

  const handlePrevious = () => {
    setIndex((prev) => prev - 1);
  };

  const handleNext = () => {
    setIndex((prev) => prev + 1);
  };

  const handleClick = (newIndex) => () => {
    setIndex(newIndex);
  };

  const slides = designs.map((design, i) => {
    return {
      url: design.image_url,
      onClick: handleClick(i),
    };
  });

  // TODO: Proper translations for labels (including prev/next buttons)
  const idToLabel = (id) => id.replace(/_/g, " ").replace(/\d+/g, "").trim();

  return (
    <Card css={cardStyles}>
      <CardHeading title={t8n("Choose a design")} css={cardHeadingStyles} />
      <Carousel currentIndex={index} slides={slides} onChange={setIndex} />
      <Controls>
        <legend css={styleHelpers.hideVisually}>
          {t8n("Choose a design")}
        </legend>
        <IconButton
          label={t8n("Previous design")}
          variant="tertiary"
          size="kilo"
          type="button"
          disabled={index === firstIndex}
          onClick={handlePrevious}
        >
          <ChevronLeft />
        </IconButton>
        {designs.map((design, i) => (
          <Control
            {...input}
            key={design.id}
            id={design.id}
            value={design.id}
            label={idToLabel(design.id)}
            checked={i === index}
            onClick={handleClick(i)}
          />
        ))}
        <IconButton
          label={t8n("Next design")}
          variant="tertiary"
          size="kilo"
          type="button"
          disabled={index === lastIndex}
          onClick={handleNext}
        >
          <ChevronRight />
        </IconButton>
      </Controls>
    </Card>
  );
};

export default DesignSelectorAdapter;
