// returns a function for translation of one language

const translateWith = (languageDict) => {
  return (key, ...values) => {
    let base = languageDict[key] || key;
    return values.reduce((accumulated, value, index) => {
      accumulated.replace("%s", value);
      const indexedPlaceholder = `%[${index + 1}]s`;
      if (base.lastIndexOf(indexedPlaceholder) >= 0) {
        return accumulated.replace(indexedPlaceholder, values[index]);
      }
      return accumulated.replace("%s", values[index]);
    }, base);
  };
};

export default translateWith;
