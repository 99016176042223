export const fieldNames = {
  design: `design`,
  amount: `amount`,
  buyerEmail: `buyer_email`,
  buyerName: `buyer_name`,
  recipientEmail: `recipient_email`,
  recipientName: `recipient_name`,
  message: `message`,
  recipientDiffers: `recipientDiffers`,
  acceptTerms: `acceptTerms`,
  acceptMarketingConsent: `acceptMarketingConsent`,
};
