/** @jsxImportSource @emotion/core */
import { withTheme } from "emotion-theming";
import { css } from "@emotion/core";

const gridContainerStyle = ({ colCount, padding }) => css`
  display: grid;
  grid-template-columns: repeat(${colCount}, 1fr);
  column-gap: ${padding};
  row-gap: ${padding};
`;

const Container = ({ style, children, theme }) => {
  if (!children) {
    return null;
  }
  const count = children.length;
  if (!count || count === 1) {
    return children;
  }

  // use two columns for 2 and 4 children,
  // three columns otherwise
  let colCount = 3;
  if (count === 2 || count === 4) {
    colCount = 2;
  }

  return (
    <div
      css={gridContainerStyle({ colCount, padding: theme.spacings.kilo })}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default withTheme(Container);
