import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import matchingLocale from "../../../utils/cardWidgetLocale";
import CardHeading from "../../layout/CardHeading";
import { withTheme } from "emotion-theming";
import { Button, Card, Heading, Notification, Text } from "@sumup/circuit-ui";
import { ReactComponent as IconLock } from "./icon_lock.svg";
import { ReactComponent as IconSecure } from "./icon_secure.svg";
import LoadingOverlay from "./loadingOverlay";
import TranslationContext from "../../../translation-context";
import NumberFormatter from "../../../utils/numberFormatter";
import { minorToMajorUnits } from "../../../utils/currency/units";

function filterAvailablePMs(methods) {
  if (!Array.isArray(methods)) {
    return methods;
  }
  return methods.filter(function (pm) {
    return pm.toLowerCase() !== "pix";
  });
}

const mountCard = (
  checkoutId,
  locale,
  onLoad,
  email,
  currency,
  onResponse,
  onChangeInstallments,
) => {
  return window.SumUpCard.mount({
    showFooter: false,
    showSubmitButton: false,
    showZipCode: currency === "USD",
    showCpfNumber: currency === "BRL",
    showInstallments: currency === "BRL",
    checkoutId: checkoutId,
    email: email,
    locale: matchingLocale(locale),
    onLoad,
    onResponse,
    onChangeInstallments,
    onPaymentMethodsLoad: function (methods) {
      const eligible = methods.eligible.map(function (pm) {
        return pm.id;
      });
      return filterAvailablePMs(eligible);
    },
  });
};

const PayForm = (props) => {
  const theme = props.theme;
  const voucher = props.voucher;
  const checkoutId = voucher.checkout_id;
  const person =
    voucher.recipient && voucher.recipient.email !== ""
      ? voucher.recipient
      : voucher.buyer;

  const cardWidget = useRef(null);
  const [sumUpCardWidget, setSumUpCardWidget] = useState(null);
  const [widgetIsLoaded, setWidgetIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [installments, setInstallments] = useState(1);
  const [shouldShowSubmitButton, setShouldShowSubmitButton] = useState(false);
  const t8n = useContext(TranslationContext);

  const onLoad = () => {
    setWidgetIsLoaded(true);
    setShouldShowSubmitButton(true);
  };

  const onResponse = (onSuccess) => {
    return (type, body) => {
      const isAuthScreen = type === "auth-screen";
      setIsLoading(type === "sent");
      setHasError(type === "error");
      setShouldShowSubmitButton(!isAuthScreen);
      if (isAuthScreen) {
        return;
      }

      if (type === "invalid") {
        // validation UI is handled in card widget
        return;
      }

      if (type !== "success" || !body || !body.status) {
        // below is only for success
        return;
      }

      const status = body.status.toUpperCase();
      const isPaid = status === "PAID";
      const isBoleto = !!body.boleto && !!body.boleto.barcode;
      if (!isPaid && !isBoleto) {
        setHasError(true);
        return;
      }

      // scroll to top
      window.scrollTo(0, 0);
      onSuccess(status);
    };
  };

  const onChangeInstallments = (newInstallmentsCount) => {
    setInstallments(newInstallmentsCount);
  };

  useEffect(() => {
    setSumUpCardWidget(
      mountCard(
        checkoutId,
        props.locale,
        onLoad,
        voucher.buyer.email,
        props.currencyCode,
        onResponse(props.onSuccess),
        onChangeInstallments,
      ),
    );
  }, [
    checkoutId,
    props.currencyCode,
    props.locale,
    props.onSuccess,
    voucher.buyer.email,
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formattedAmount = NumberFormatter(props.locale)(
    minorToMajorUnits(voucher.amount, props.currencyCode),
    props.currencyCode,
  );
  let paidAmount = voucher.paid_amount
    ? NumberFormatter(props.locale)(
        minorToMajorUnits(voucher.paid_amount, props.currencyCode) /
          installments,
        props.currencyCode,
      )
    : formattedAmount;
  if (installments > 1) {
    paidAmount = `${installments} x ${paidAmount}`;
  }
  const textColor = voucher.theme ? theme.colors.n800 : theme.colors.white;
  return (
    <Fragment>
      {
        // Summary of created voucher
      }
      <Card
        style={{
          backgroundColor: voucher.theme
            ? theme.colors.white
            : theme.colors.n800,
          color: textColor,
          marginBottom: theme.spacings.mega,
          padding: voucher.theme ? 0 : theme.spacings.tera,
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        {voucher.theme && (
          <div>
            <img
              src={voucher.theme.image_url}
              alt="voucher"
              style={{ width: "100%" }}
            />
          </div>
        )}
        <div style={{ padding: voucher.theme ? theme.spacings.tera : 0 }}>
          <Heading
            size="kilo"
            style={{
              margin: 0,
              marginBottom: theme.spacings.bit,
              textTransform: "uppercase",
              color: textColor,
            }}
            as="h2"
          >
            {props.merchantName}
          </Heading>
          <Heading size="peta" style={{ margin: 0, color: textColor }} as="h3">
            {formattedAmount}
          </Heading>
          <Text
            size="kilo"
            style={{
              margin: 0,
              marginTop: theme.spacings.giga,
              fontWeight: "bold",
            }}
          >
            {person.name}
          </Text>
          <Text size="kilo" style={{ margin: theme.spacings.bit }}>
            {person.email}
          </Text>
          {!voucher.message || voucher.message === "" ? null : (
            <Text size="kilo" style={{ margin: 0 }}>
              &quot;{voucher.message}&quot;
            </Text>
          )}
        </div>
      </Card>
      {
        // Card Widget form with error message and submit button
        // -> use zero padding to allow card widget's internal (non-configurable) padding.
      }
      <Card
        style={{
          padding: 0,
          position: "relative",
          // minHeight to avoid "jumping" when card widget is loaded
          minHeight: widgetIsLoaded ? null : "400px",
          display: "block",
        }}
      >
        <IconSecure
          style={{
            position: "absolute",
            right: theme.spacings.giga,
            top: theme.spacings.giga,
          }}
        />
        <CardHeading
          title={t8n("Pay securely")}
          style={{
            margin: 0,
            padding: `${theme.spacings.giga} ${theme.spacings.giga} 0 ${theme.spacings.giga}`,
          }}
        />
        <div
          key={`card-widget-div-${checkoutId}`}
          ref={cardWidget}
          id="sumup-card"
        />

        {
          // Submit button and error
        }
        <div
          style={{
            margin: `0 ${theme.spacings.giga}`,
            paddingBottom: theme.spacings.giga,
          }}
        >
          {hasError && (
            <Notification variant="warning">
              <Heading size="kilo" as="h4">
                {t8n(
                  "Something went wrong. Please try again in a few minutes.",
                )}
              </Heading>
            </Notification>
          )}
          <Button
            variant="primary"
            type="submit"
            style={{
              width: "100%",
              visibility: shouldShowSubmitButton ? "visible" : "hidden",
            }}
            onClick={sumUpCardWidget ? sumUpCardWidget.submit : null}
          >
            <IconLock
              style={{
                verticalAlign: "middle",
                position: "relative",
                top: "-3px",
                marginRight: theme.spacings.byte,
              }}
            />
            {t8n("Pay %s", paidAmount)}
          </Button>
        </div>
        {isLoading && <LoadingOverlay />}
      </Card>
    </Fragment>
  );
};

export default withTheme(PayForm);
