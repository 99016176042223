/** @jsxImportSource @emotion/core */
import { Fragment, useContext, useEffect } from "react";
import { Card, Heading, Text, spacing } from "@sumup/circuit-ui";
import { withTheme } from "emotion-theming";
import { ReactComponent as Checkmark } from "./checkmark.svg";
import Summary from "./orderSummary";
import TranslationContext from "../../../translation-context";

const checkmarkSpacing = {
  top: 0,
  bottom: "kilo",
  left: "auto",
  right: "auto",
};

const CompleteStage = ({ theme, voucher, locale, currencyCode }) => {
  const t8n = useContext(TranslationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Card
        style={{
          textAlign: "center",
          padding: theme.spacings.tera,
          marginBottom: theme.spacings.giga,
        }}
      >
        <Checkmark css={spacing(checkmarkSpacing)} />
        <Heading size="mega">{t8n("Thank you for your purchase")}</Heading>
        <Text>
          {t8n(
            "The gift card has been sent to %s",
            voucher.recipient ? voucher.recipient.email : voucher.buyer.email,
          )}
        </Text>
      </Card>
      <Summary
        theme={theme}
        order={voucher}
        locale={locale}
        currencyCode={currencyCode}
      />
    </Fragment>
  );
};

export default withTheme(CompleteStage);
