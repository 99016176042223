import { TYPE } from "./model";
import NumberFormatter from "../numberFormatter";
import { minorToMajorUnits } from "../currency/units";

const desc = (t8n, locale, currencyCode, discountRule) => {
  const formatter = NumberFormatter(locale);
  const maxAmount = discountRule.maximum_discount_value
    ? formatter(
        minorToMajorUnits(discountRule.maximum_discount_value, currencyCode),
        currencyCode,
      )
    : null;
  const minValue =
    discountRule.minimum_voucher_value && discountRule.minimum_voucher_value > 0
      ? formatter(
          minorToMajorUnits(discountRule.minimum_voucher_value, currencyCode),
          currencyCode,
        )
      : null;
  switch (discountRule.type) {
    case TYPE.percentage: {
      const value = `${formatter(discountRule.value / 100)}%`;
      if (minValue && maxAmount) {
        return t8n(
          "%s discount applies to gift cards %s and above, with a maximum discount of %s",
          value,
          minValue,
          maxAmount,
        );
      }
      if (!maxAmount && !minValue) {
        return t8n("%s discount applies to all gift cards", value);
      }
      if (!maxAmount) {
        return t8n(
          "%s discount applies to gift cards %s and above",
          value,
          minValue,
        );
      }

      if (!minValue) {
        return t8n(
          "%s discount applies to all gift cards, with a maximum discount of %s",
          value,
          maxAmount,
        );
      }
      break;
    }
    default:
      break;
  }
  return null;
};

export default desc;
