import PropTypes from "prop-types";

export const TYPE = {
  percentage: "PERCENTAGE",
  absolute: "ABSOLUTE",
};

export const DiscountPropTypes = {
  type: PropTypes.oneOf([TYPE.absolute, TYPE.percentage]),
  value: PropTypes.number.isRequired,
  minimum_voucher_value: PropTypes.number,
  maximum_discount_value: PropTypes.number,
};
