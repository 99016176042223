const sanitizeQueryString = (queryString) => {
  if (!URLSearchParams) {
    return null;
  }

  var params = new URLSearchParams(queryString);
  params.delete("checkout_id");
  return params.toString();
};

export default sanitizeQueryString;
