// based on https://gist.github.com/DavidKuennen/443121e692175d6fc145e1efb0284ec9
// with modification to reduce amount of detail

import sanitize_query from "./sanitize_query";

const context = window;
const trackingId = "UA-146933472-1";
const options = {
  colorDepth: true,
  characterSet: true,
  screenSize: true,
  language: true,
  // important for GDPR compliance
  anonymizeIp: true,
};
const doc = document;
const nav = navigator || {};
const storage = localStorage;
const encode = encodeURIComponent;
const typeException = "exception";
const generateId = () => Math.random().toString(36);
const getId = () => {
  if (!storage.cid) {
    storage.cid = generateId();
  }
  return storage.cid;
};
const serialize = (obj) => {
  var str = [];
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      if (obj[p] !== undefined) {
        str.push(encode(p) + "=" + encode(obj[p]));
      }
    }
  }
  return str.join("&");
};
const track = (
  type,
  path,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  exceptionDescription,
  exceptionFatal,
) => {
  const url = "https://www.google-analytics.com/collect";
  let referrer = doc.referrer || undefined;
  if (
    referrer &&
    (referrer.includes(context.location.hostname) ||
      referrer.includes("api.sumup.com"))
  ) {
    // do not track internal referrers to avoid leaking sensitive codes
    referrer = undefined;
  }

  if (doc.location.search) {
    path += "?" + sanitize_query(doc.location.search);
  }
  const data = serialize({
    v: "1",
    ds: "web",
    aip: options.anonymizeIp ? 1 : undefined,
    tid: trackingId,
    cid: getId(),
    t: type || "pageview",
    sd:
      options.colorDepth && context.screen.colorDepth
        ? `${context.screen.colorDepth}-bits`
        : undefined,
    dr: referrer,
    // omit title to avoid sending merchant name to GA: dt: doc.title,
    dl: doc.location.origin + path,
    ul: options.language ? (nav.language || "").toLowerCase() : undefined,
    de: options.characterSet ? doc.characterSet : undefined,
    sr: options.screenSize
      ? `${(context.screen || {}).width}x${(context.screen || {}).height}`
      : undefined,
    vp:
      options.screenSize && context.visualViewport
        ? `${(context.visualViewport || {}).width}x${
            (context.visualViewport || {}).height
          }`
        : undefined,
    ec: eventCategory || undefined,
    ea: eventAction || undefined,
    el: eventLabel || undefined,
    ev: eventValue || undefined,
    exd: exceptionDescription || undefined,
    exf:
      typeof exceptionFatal !== "undefined" && !!exceptionFatal === false
        ? 0
        : undefined,
  });

  if (nav.sendBeacon) {
    nav.sendBeacon(url, data);
  } else {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.send(data);
  }
};

const trackPageView = (path) => track("pageview", path);
const trackEvent = (category, path, action, label, value) =>
  track("event", path, category, action, label, value);
const trackException = (description, fatal) =>
  track(typeException, null, null, null, null, description, fatal);

const gaTrackers = {
  trackPageView,
  trackEvent,
  trackException,
};

export default gaTrackers;
