import React, { useCallback, useContext, useEffect, useState } from "react";
import { withTheme } from "emotion-theming";
import { Checkbox, Text } from "@sumup/circuit-ui";

import NumberFormatter from "../../../utils/numberFormatter";
import TranslationContext from "../../../translation-context";
import PresetPicker from "./presetPicker";
import CustomAmount from "./customAmount";

const AmountSelector = ({
  amounts,
  locale,
  onChange,
  currency,
  theme,
  discountFunction,
  allowsCustomAmounts,
}) => {
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [useCustomAmount, setUseCustomAmount] = useState(false);
  const numberFormatter = NumberFormatter(locale);
  const t8n = useContext(TranslationContext);
  const hasAmounts = amounts && amounts.length > 0;
  const showCustomAmountsOption = allowsCustomAmounts || !hasAmounts;
  amounts.sort((a, b) => {
    return a - b;
  });

  const changeAmount = useCallback(
    (amount) => {
      setSelectedAmount(amount);
      onChange(amount);
    },
    [onChange],
  );

  useEffect(() => {
    if (!showCustomAmountsOption && amounts.length === 1) {
      changeAmount(amounts[0]);
    }
  }, [amounts, showCustomAmountsOption, changeAmount]);

  return (
    <div>
      {hasAmounts && (
        <PresetPicker
          amounts={amounts}
          selectedAmount={selectedAmount}
          onChange={(newAmount) => {
            changeAmount(newAmount);
            setUseCustomAmount(false);
          }}
          numberFormatter={(amount) => numberFormatter(amount, currency)}
          discountFunction={discountFunction}
        />
      )}
      {hasAmounts && showCustomAmountsOption && (
        <Checkbox
          name="useCustomAmount"
          onChange={() => setUseCustomAmount(!useCustomAmount)}
          checked={useCustomAmount}
          style={{ maxWidth: "66%" }}
        >
          <Text size="kilo" noMargin style={{ marginTop: "2px" }}>
            {" "}
            {t8n("Enter custom amount")}
          </Text>
        </Checkbox>
      )}
      {((showCustomAmountsOption && useCustomAmount) || !hasAmounts) && (
        <CustomAmount
          locale={locale}
          currency={currency}
          onChange={changeAmount}
          numberFormatter={(amount) => numberFormatter(amount, currency)}
          style={hasAmounts ? { marginTop: theme.spacings.kilo } : null}
          discountFunction={discountFunction}
          label={t8n("Enter custom amount")}
          hideLabel
        />
      )}
    </div>
  );
};

export default withTheme(AmountSelector);
