import React, { useContext, useEffect, useState } from "react";
import { withTheme } from "emotion-theming";
import { Card, Heading, Notification } from "@sumup/circuit-ui";
import CompleteStage from "./complete";
import Pay from "./pay";
import TranslationContext from "../../translation-context";
import GoogleAnalytics from "../../utils/analytics/google";

const isPaid = (status) => status === "PAID";

const ConfirmStage = ({
  theme,
  merchantVoucherCode,
  voucher,
  currencyCode,
  locale,
  ...props
}) => {
  const [status, setStatus] = useState(voucher?.status);
  const t8n = useContext(TranslationContext);

  useEffect(() => {
    const url = isPaid(status)
      ? `/order/${merchantVoucherCode}/confirm`
      : `/order/${merchantVoucherCode}/pay`;
    GoogleAnalytics.trackPageView(url);
  });

  if (!voucher) {
    return (
      <Card>
        <Notification variant="error">
          <Heading size="kilo" as="h4" noMargin>
            {t8n("Something went wrong. Please try again in a few minutes.")}
          </Heading>
        </Notification>
      </Card>
    );
  }

  return isPaid(status) ? (
    <CompleteStage
      theme={theme}
      voucher={voucher}
      currencyCode={currencyCode}
      locale={locale}
      {...props}
    />
  ) : (
    <Pay
      theme={theme}
      voucher={voucher}
      currencyCode={currencyCode}
      locale={locale}
      onSuccess={setStatus}
      {...props}
    />
  );
};

export default withTheme(ConfirmStage);
