import { Input } from "@sumup/circuit-ui";
import React from "react";

const InputAdapter = ({ input, meta, ...props }) => {
  const invalid = Boolean(meta.touched && meta.error);
  const validationHint = invalid ? meta.error : null;
  return (
    <Input
      {...input}
      invalid={invalid}
      validationHint={validationHint}
      {...props}
    />
  );
};

export default InputAdapter;
