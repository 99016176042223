/** @jsxImportSource @emotion/core */
import { css } from "@emotion/core";
import { Heading } from "@sumup/circuit-ui";

const CardHeading = ({ title, ...props }) => (
  <Heading
    size="mega"
    as="h3"
    css={(theme) => css`
      margin-top: 0;
      margin-bottom: ${theme.spacings.mega};
    `}
    {...props}
  >
    {title}
  </Heading>
);

export default CardHeading;
