/** @jsxImportSource @emotion/core */
import { useContext, useEffect, useState } from "react";
import { Text } from "@sumup/circuit-ui";
import { css } from "@emotion/core";
import { CurrencyInput } from "@sumup/circuit-ui";
import TranslationContext from "../../../translation-context";
import { osname } from "../../../utils/osdetection";

const containerStyles = css`
  position: relative;
`;

const discountStyles = (theme) => css`
  color: ${theme.colors.b500};
  position: absolute;
  right: 0;
  top: calc(-1 * ${theme.spacings.mega});
  max-width: 33%;
  text-align: right;
  transform: translateY(-80%);
`;

// prevents possibly showing the incorrect decimal separator on iOS
const inputmode = osname === "iOS" ? "text" : "decimal";

const CustomAmount = ({
  onChange,
  numberFormatter,
  discountFunction,
  style,
  ...props
}) => {
  const t8n = useContext(TranslationContext);
  const [value, setValue] = useState("");
  const [discount, setDiscount] = useState(0);
  const showDiscount = discountFunction && discount > 0;
  const discountedAmount = numberFormatter(value - discount);
  const discountId = "discount-info";

  const formattedValue = value ? numberFormatter(value) : "";

  const handleChange = ({ value: newValue }) => {
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    const newDiscount = discountFunction ? discountFunction(value) : 0;
    setDiscount(newDiscount);
  }, [value, discountFunction]);

  return (
    <div css={containerStyles} style={style}>
      <CurrencyInput
        value={formattedValue}
        onValueChange={handleChange}
        placeholder={0}
        aria-controls={discountId}
        inputMode={inputmode}
        {...props}
      />

      {showDiscount && (
        <Text
          size="kilo"
          noMargin
          css={discountStyles}
          aria-live="polite"
          id={discountId}
        >
          {t8n("Pay %s", discountedAmount)}
        </Text>
      )}
    </div>
  );
};

export default CustomAmount;
