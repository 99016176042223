const createVoucher = async (merchantVoucherCode, slug, body) => {
  const readyPromise = new Promise((resolve) => {
    window.grecaptcha.ready(function () {
      resolve(true);
    });
  });

  return readyPromise.then(() => {
    return window.grecaptcha
      .execute(window.recaptchaSiteKey, { action: "submit" })
      .then(function (token) {
        body["recaptchaToken"] = token;

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        };

        // should be called from main order page
        return fetch(
          "/create/" + merchantVoucherCode + "/" + slug,
          requestOptions,
        ).then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        });
      });
  });
};

export default createVoucher;
