import { Checkbox } from "@sumup/circuit-ui";
import React, { useState } from "react";

const CheckBoxAdapter = ({ input, children, ...props }) => {
  const [checked, setChecked] = useState(Boolean(input.value));
  const handleChange = (e) => {
    setChecked((prev) => !prev);
    input.onChange(e);
  };
  return (
    <Checkbox {...input} checked={checked} onChange={handleChange} {...props}>
      {children}
    </Checkbox>
  );
};

export default CheckBoxAdapter;
