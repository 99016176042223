import React from "react";
import { withTheme } from "emotion-theming";
import { Heading, Text } from "@sumup/circuit-ui";
import ResponsiveWidthContainer from "../layout/ResponsiveWidthContainer";
import { ReactComponent as Icon } from "./icon.svg";

const Container = ({ name, line1, line2, postCode, city, theme }) => {
  return (
    <header
      style={{
        backgroundColor: theme.colors.white,
        boxShadow:
          "0px 2px 2px rgba(12, 15, 20, 0.06), 0px 1px 1px rgba(12, 15, 20, 0.06), 0px -1px 0px rgba(12, 15, 20, 0.02)",
        marginBottom: theme.spacings.giga,
      }}
    >
      <ResponsiveWidthContainer>
        <div
          style={{
            padding: theme.spacings.giga,
            textAlign: "center",
          }}
        >
          <Icon />
          <Heading
            size="giga"
            as="h1"
            style={{ marginTop: theme.spacings.byte }}
            noMargin
          >
            {name}
          </Heading>
          <Text size="kilo" style={{ marginBottom: 0 }}>
            {line1}
            <br />
            {line2 !== "" && (
              <span>
                {line2}
                <br />
              </span>
            )}
            {postCode} {city}
          </Text>
        </div>
      </ResponsiveWidthContainer>
    </header>
  );
};

export default withTheme(Container);
