import { minorToMajorUnits } from "../currency/units";
import { DiscountPropTypes, TYPE } from "./model";
import PropTypes from "prop-types";

const discountFunc = ({ discounts }) => {
  if (!discounts) {
    return () => {
      return 0;
    };
  }

  return (value, currency) => {
    let rule = discounts.find((rule) => {
      return (
        rule.type === TYPE.percentage &&
        (!rule.minimum_voucher_value ||
          minorToMajorUnits(rule.minimum_voucher_value, currency) <= value)
      );
    });
    if (!rule) {
      return 0;
    }
    if (rule.type === TYPE.percentage) {
      // value is percentage points with 2 decimals as integer (10.00% -> 1 000)
      // divide by 10 000 for floating point (1 000/10 000 -> 0.1 ≝ 10%)
      const discount = (rule.value / 10_000) * value;
      if (rule.maximum_discount_value) {
        const maxDiscount = minorToMajorUnits(
          rule.maximum_discount_value,
          currency,
        );
        return maxDiscount <= discount ? maxDiscount : discount;
      }
      return discount;
    }
    return 0;
  };
};
discountFunc.PropTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape(DiscountPropTypes)),
};

const computeDiscount = (discounts) => discountFunc({ discounts });

export default computeDiscount;
