import { Spinner } from "@sumup/circuit-ui";
import React from "react";
import { withTheme } from "emotion-theming";

const overlay = ({ theme }) => (
  <div
    style={{
      display: "flex",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      position: "absolute",
      top: theme.spacings.byte,
      left: theme.spacings.byte,
      right: theme.spacings.byte,
      bottom: theme.spacings.byte,
      textAlign: "center",
      alignItems: "center",
      pointerEvents: "all",
    }}
  >
    <Spinner
      size={Spinner.GIGA}
      dark={true}
      style={{ display: "block", margin: "0 auto" }}
    />
  </div>
);

export default withTheme(overlay);
