/** @jsxImportSource @emotion/core */
import { useRef, useEffect } from "react";
import { withTheme } from "emotion-theming";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { debounce } from "throttle-debounce";
import { styleHelpers } from "@sumup/circuit-ui";

const TRACK_PADDING = "zetta";
const SLIDE_GAP = "kilo";

const trackStyles = ({ theme }) => css`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 ${theme.spacings[TRACK_PADDING]};
  padding: 0 ${theme.spacings[TRACK_PADDING]};
  -webkit-overflow-scrolling: touch;

  &::after {
    content: "";
    display: block;
    width: ${theme.spacings[TRACK_PADDING]};
    height: 1px;
    flex-shrink: 0;
  }
`;

const Track = styled("div")(trackStyles, styleHelpers.hideScrollbar);

const slideStyles = ({ theme, url }) => css`
  margin-left: ${theme.spacings[SLIDE_GAP]};
  scroll-snap-align: start;
  width: 100%;
  flex-shrink: 0;
  height: 0;
  padding-top: 62.5%;
  overflow: hidden;
  background-color: ${theme.colors.n200};
  background-image: url("${url}");
  background-size: cover;
  border-radius: ${theme.borderRadius.giga};
  cursor: pointer;

  &:first-of-type {
    margin-left: 0;
  }
`;

const Slide = styled("div")(slideStyles);

const Carousel = ({ slides, currentIndex, onChange, theme }) => {
  const trackRef = useRef();

  const numberOfSlides = slides.length;

  useEffect(() => {
    const trackPadding = parseInt(theme.spacings[TRACK_PADDING], 10);
    const slideGap = parseInt(theme.spacings[SLIDE_GAP], 10);
    const { scrollLeft, scrollWidth } = trackRef.current;
    const scrollDistance = Math.floor(
      (scrollWidth - 2 * trackPadding + slideGap) *
        (currentIndex / numberOfSlides),
    );

    if (scrollDistance !== scrollLeft) {
      const prefersReducedMotion = window.matchMedia(
        "(prefers-reduced-motion: reduce)",
      ).matches;
      trackRef.current.scrollTo({
        left: scrollDistance,
        behavior: prefersReducedMotion ? "auto" : "smooth",
      });
    }
  }, [currentIndex, numberOfSlides, theme]);

  useEffect(() => {
    const handleScroll = debounce(200, () => {
      const { scrollLeft, scrollWidth } = trackRef.current;
      const index = Math.round((scrollLeft / scrollWidth) * numberOfSlides);

      if (currentIndex !== index) {
        onChange(index);
      }
    });

    const trackEl = trackRef.current;

    trackEl.addEventListener("scroll", handleScroll);

    return () => {
      trackEl.removeEventListener("scroll", handleScroll);
    };
  }, [currentIndex, numberOfSlides, onChange]);

  return (
    <Track ref={trackRef}>
      {slides.map((slide, index) => (
        <Slide key={slide.url} {...slide} />
      ))}
    </Track>
  );
};

export default withTheme(Carousel);
