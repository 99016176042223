import { fieldNames } from "./const";
import { majorToMinorUnits } from "../../utils/currency/units";

// converts form fields to the payload to be sent for voucher creation
const generatePayload = (formValues, currencyCode) => {
  if (!currencyCode || currencyCode === "") {
    return null;
  }
  // required fields:
  let userEnteredAmount = formValues[fieldNames.amount];
  const buyerName = formValues[fieldNames.buyerName];
  const buyerEmail = formValues[fieldNames.buyerEmail];
  if (!userEnteredAmount || !buyerName || !buyerEmail) {
    return null;
  }

  const recipientDiffer = formValues[fieldNames.recipientDiffers];
  const recipient = recipientDiffer
    ? {
        name: formValues[fieldNames.recipientName].trim(),
        email: formValues[fieldNames.recipientEmail].trim(),
      }
    : null;
  const theme_id =
    formValues[fieldNames.design] === false
      ? null
      : formValues[fieldNames.design];

  return {
    amount: majorToMinorUnits(userEnteredAmount, currencyCode),
    message: recipientDiffer
      ? (formValues[fieldNames.message] || "").trim()
      : null,
    buyer: {
      name: buyerName.trim(),
      email: buyerEmail.trim(),
    },
    marketingConsent: formValues[fieldNames.acceptMarketingConsent],
    recipient,
    theme_id: theme_id,
  };
};

export default generatePayload;
