import React from "react";
import { withTheme } from "emotion-theming";
import AmountSelector from "./amountSelector";

const AmountSelectorAdapter = ({
  input: { onChange },
  locale,
  currency,
  discountFunction,
  amounts,
  allowsCustomAmounts,
}) => (
  <AmountSelector
    locale={locale}
    currency={currency}
    onChange={onChange}
    discountFunction={discountFunction}
    allowsCustomAmounts={allowsCustomAmounts}
    amounts={amounts}
  />
);

export default withTheme(AmountSelectorAdapter);
