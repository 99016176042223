import React from "react";
import { withTheme } from "emotion-theming";

const Container = ({ theme, style, children }) => {
  return (
    <div
      style={{
        display: "block",
        minWidth: "300px",
        maxWidth: "520px",
        margin: `0 auto`,
        padding: `0 ${theme.spacings.mega}`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default withTheme(Container);
