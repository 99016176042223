import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

function runApplication(data, node) {
  ReactDOM.render(
    <BrowserRouter>
      <React.StrictMode>
        <App
          merchant={data.merchant}
          locale={data.locale}
          voucher={data.voucher}
          translations={data.translations || {}}
        />
      </React.StrictMode>
    </BrowserRouter>,
    node,
  );
}

window.runApplication = runApplication;
