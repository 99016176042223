import React, { useState, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "emotion-theming";
import { BaseStyles } from "@sumup/circuit-ui";
import { light } from "@sumup/design-tokens";

import MerchantHeader from "./components/merchant/header";
import Form from "./components/create";
import ConfirmStage from "./components/confirm";
import ResponsiveWidthContainer from "./components/layout/ResponsiveWidthContainer";
import PropTypes from "prop-types";
import { Routes, Route, useNavigate } from "react-router-dom";
import CreateVoucher from "./calls/createVoucher";
import TranslationContext from "./translation-context";
import TranslationFunc from "./utils/translation";

const App = (props) => {
  const navigate = useNavigate();

  const [voucher, setVoucher] = useState(props.voucher);
  const [createError, setCreateError] = useState(null);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const onSubmit = (code, slug, body) => {
    CreateVoucher(code, slug, body)
      .then((data) => {
        const voucher = data.voucher;
        setCreateError(null);
        setVoucher(voucher);
        navigate(`/order/${code}/${slug}/${voucher.code}`);
      })
      .catch((error) => {
        setCreateError(error);
      });
  };
  const merchant = props.merchant;

  return (
    <ThemeProvider theme={light}>
      <BaseStyles />
      <TranslationContext.Provider
        value={TranslationFunc(props.translations || {})}
      >
        <MerchantHeader
          name={merchant.name}
          line1={merchant.address_line1}
          line2={merchant.address_line2}
          postCode={merchant.post_code}
          city={merchant.city}
        />
        <Routes>
          <Route
            path="/order/:code/:slug"
            element={
              <ResponsiveWidthContainer>
                <Form
                  locale={props.locale}
                  currency={merchant.currency}
                  designs={merchant.available_themes}
                  discounts={merchant.discounts}
                  presets={merchant.presets}
                  customAmounts={merchant.custom_amounts}
                  onSubmitForm={onSubmit}
                  error={createError}
                />
              </ResponsiveWidthContainer>
            }
          />
          <Route
            path="/order/:code/:slug/:voucher"
            element={
              <ResponsiveWidthContainer>
                <ConfirmStage
                  locale={props.locale}
                  voucher={voucher}
                  currencyCode={merchant.currency}
                  merchantName={merchant.name}
                  merchantVoucherCode={merchant.voucher_code}
                />
              </ResponsiveWidthContainer>
            }
          />
        </Routes>
      </TranslationContext.Provider>
    </ThemeProvider>
  );
};

App.propTypes = {
  locale: PropTypes.string.isRequired,
  merchant: PropTypes.object.isRequired,
};

export default App;
