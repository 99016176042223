import {
  format,
  formatCurrency,
  isNumberFormatSupported,
  resolveFormat,
} from "@sumup/intl";
const trailingZeroDecimalsDot = /\.00/;
const trailingZeroDecimalsComma = /,00/;

const localize = isNumberFormatSupported
  ? format
  : (number, locale) => Number.prototype.toLocaleString.call(number, locale);

const localizeWithCurrency = isNumberFormatSupported
  ? formatCurrency
  : (number, locale, currency) =>
      Number.prototype.toLocaleString.call(number, locale, {
        style: "currency",
        currency,
      });

const decimalDelimiterByLocale = (locale) => {
  const localeSettings = resolveFormat(locale);
  return localeSettings && localeSettings.decimalDelimiter
    ? localeSettings.decimalDelimiter
    : Number.prototype.toLocaleString.call(1.1, locale).substr(-2, 1);
};

const NumberFormatter = (locale) => {
  const decimalDelimiter = decimalDelimiterByLocale(locale);

  const removeTrailingZeroDecimals = (formattedAmount) =>
    formattedAmount.replace(
      decimalDelimiter === "."
        ? trailingZeroDecimalsDot
        : trailingZeroDecimalsComma,
      "",
    );

  return (number, currency) => {
    if (isNaN(number)) {
      return "";
    }

    const localizedNumber = !currency
      ? localize(number, locale)
      : localizeWithCurrency(number, locale, currency);
    return removeTrailingZeroDecimals(localizedNumber);
  };
};

export default NumberFormatter;
